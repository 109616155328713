<template>
  <div class="general-login-container">
    <div class="login-container">
      <form @submit.prevent="login">
        <div class="form-container">
          <img src="../assets/img/AppLogo.png" alt="Idelize" />

          <div class="form-fields">
            <div class="fields">
              <input
                v-model="username"
                type="text"
                placeholder="Nome de usuário"
              />
              <input v-model="email" type="email" placeholder="E-mail" />
              <input v-model="password" type="password" placeholder="Senha" />
              <input
                v-model="checkPassword"
                type="password"
                placeholder="Repita senha"
              />
            </div>

            <button type="submit">Criar conta</button>
          </div>
          <p>
            Criando uma conta concordo com os
            <router-link to="">termos & condições</router-link>
          </p>
        </div>
      </form>
    </div>
    <AppLoginFooter />
  </div>
</template>

<script>
import "@/styles/login.scss";
import AppLoginFooter from "../components/scaffold/AppLoginFooter.vue";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import Swal from "sweetalert2";

export default {
  name: "AppRegister",
  components: { AppLoginFooter },
  data() {
    return {
      email: "",
      password: "",
      checkPassword: "",
    };
  },
  methods: {
    async cadastro() {
      const auth = getAuth();
      signInWithEmailAndPassword(auth, this.email, this.password)
        .then((userCredential) => {
          const user = userCredential.user;

          if (user) {
            Swal.fire({
              title: "Cadastro realizado com sucesso!",
              text: "Seja bem-vindo ao Idealize!",
              icon: "success",
              confirmButtonText: "Ok",
            });
            this.$router.push("/login");
          }
        })
        .catch((error) => {
          console.error("ERRO", error);
          Swal.fire({
            title: "Falha ao realizar login!",
            text: "Por favor tente novamente",
            icon: "error",
            confirmButtonText: "Ok",
          });
        });
    },
  },
};
</script>
