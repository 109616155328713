<template>
  <div class="container">
    <div class="sidebar" :class="{ collapsed: isCollapsed }">
      <img
        src="../assets/img/icons/ic_menu.png"
        alt="menu"
        class="menu-button"
        @click="toggleSidebar"
      />
      <div class="top-content">
        <img src="@/assets/img/AppLogo.png" alt="IdealizeApp" />
        <nav class="navigation">
          <router-link :to="{ path: 'painel' }">Dashboard</router-link>
          <router-link :to="{ path: 'perfil' }">Perfil</router-link>
          <router-link :to="{ path: 'configuracoes' }"
            >Configurações
          </router-link>
          <router-link class="menu-categoria" :to="{ path: '/ideias' }"
            >Minhas ideias</router-link
          >

          <ul class="sub-menu">
            <router-link :to="{ path: 'categorias' }">Categorias</router-link>
            <router-link :to="{ path: 'recentes' }">Recentes</router-link>
            <router-link :to="{ path: 'favoritas' }">Favoritas</router-link>
            <router-link :to="{ path: 'compartilhadas' }"
              >Compartilhadas</router-link
            >
            <router-link :to="{ path: 'lixeira' }">Lixeira </router-link>
          </ul>
        </nav>
      </div>

      <div class="down-content">
        <div class="actions">
          <img src="../assets/img/icons/ic_support.png" alt="Suporte" />
          <img src="../assets/img/icons/ic_faq.png" alt="FAQ" />
          <img src="../assets/img/icons/ic_help.png" alt="Ajuda" />
        </div>

        <div class="copyright">
          <strong class="makeby">Feito com carinho por </strong>
          <img src="../assets/img/Crew.png" alt="Crew" />
        </div>
      </div>
    </div>

    <div class="content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import "@/styles/panel.scss";
export default {
  name: "AppPanel",
  components: {},
  data() {
    return {
      isCollapsed: false,
    };
  },
  methods: {
    toggleSidebar() {
      this.isCollapsed = !this.isCollapsed;
    },
  },
};
</script>
