import "@/styles/global.scss";
import * as vue from "vue";
import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

import Router from "./routes";
import AppVue from "./App.vue";

import * as Firebase from "firebase/app";
import "firebase/auth";
import auth from "./store/auth";

const store = createStore({
  modules: {
    auth: auth,
  },
  plugins: [
    createPersistedState(), // Persiste todo o estado da store
  ],
});

const firebaseConfig = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGE_SENDER_IR,
  appId: process.env.VUE_APP_APP_ID,
};

const app = Firebase.initializeApp(firebaseConfig);
export const firebaseApp = app;

const IdealizeApp = vue.createApp(AppVue);

IdealizeApp.use(store);
IdealizeApp.use(Router);
IdealizeApp.mount("#app");
