<template>
  <div class="page-header">
    <h1>{{ pageTitle }}</h1>
    <div class="action-buttons">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppPageHeader",
  props: {
    pageTitle: String,
  },
};
</script>
