<template>
  <div class="not-found-container">
    <img src="../assets/img/AppLogoWhite.png" alt="" srcset="" />
    <h1>404 Página não encontrada!</h1>
  </div>
</template>

<script>
import "@/styles/notFound.scss";
export default {
  name: "AppNotFound",
  components: {},
};
</script>
