import AppDashboard from "@/components/AppDashboard.vue";
import AppEditor from "@/components/AppEditor.vue";

import AppIdeaCategories from "@/components/AppIdeaCategories.vue";
import AppIdeaFavorites from "@/components/AppIdeaFavorites.vue";
import AppIdeaList from "@/components/AppIdeaList.vue";
import AppIdeaProfile from "@/components/AppIdeaProfile.vue";
import AppIdeaRecents from "@/components/AppIdeaRecents.vue";
import AppIdeaSettings from "@/components/AppIdeaSettings.vue";
import AppIdeaShared from "@/components/AppIdeaShared.vue";
import AppIdeaTrash from "@/components/AppIdeaTrash.vue";

import AppLogin from "@/views/AppLogin.vue";
import AppNotFound from "@/views/AppNotFound.vue";
import AppPanel from "@/views/AppPanel.vue";
import AppRegister from "@/views/AppRegister.vue";
import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  { path: "/:pathMatch(.*)*", name: "NotFound", component: AppNotFound },
  { path: "/login", component: AppLogin },
  { path: "/cadastro", component: AppRegister },

  {
    path: "/",
    component: AppPanel,
    children: [
      { path: "painel", component: AppDashboard },
      { path: "nova", component: AppEditor },
      { path: "perfil", component: AppIdeaProfile },
      {
        path: "configuracoes",
        component: AppIdeaSettings,
      },
      { path: "ideias", component: AppIdeaList },
      {
        path: "categorias",
        component: AppIdeaCategories,
      },
      { path: "recentes", component: AppIdeaRecents },
      {
        path: "favoritas",
        component: AppIdeaFavorites,
      },
      {
        path: "compartilhadas",
        component: AppIdeaShared,
      },
      { path: "lixeira", component: AppIdeaTrash },
    ],
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
