<template>
  <div class="new-content">
    <div class="page-header">
      <h1>Nova ideia</h1>
    </div>

    <div class="idea-info">
      <h3>Detalhes</h3>
      <div class="fields">
        <div class="field">
          <label for="nome">Nome</label>
          <input
            name="nome"
            type="text"
            placeholder="Insira um identificador para sua ideia"
          />
        </div>
        <div class="field">
          <label for="Categoria">Categoria</label>
          <input
            name="categoria"
            type="text"
            placeholder="Insira uma categoria para sua ideia"
          />
        </div>
      </div>
    </div>

    <div class="markdown-editor">
      <textarea
        v-model="markdownText"
        placeholder="Digite sua ideia utilizando a notação Markdown"
      ></textarea>
      <div class="markdown-preview" v-html="compiledMarkdown"></div>
    </div>

    <div class="info">
      <h4>Comandos</h4>

      <div class="categories">
        <div class="cat">
          <strong>Titulos</strong>
          <p># Título</p>
          <p>## Título</p>
        </div>

        <div class="cat">
          <strong>Textos</strong>
          <p>**Texto em negrito**</p>
          <p>*Texto em itálico*</p>
        </div>

        <div class="cat">
          <strong>Lista não ordenada</strong>
          <p>- Item 1 - Item 2 - Subitem 2.1 - Subitem 2.2</p>
        </div>

        <div class="cat">
          <strong>Lista ordenada</strong>
          <p>1. Primeiro item 2. Segundo item 1. Subitem 2.1 2. Subitem 2.2</p>
        </div>

        <div class="cat">
          <strong>Links</strong>
          <p>[Texto do link](URL)</p>
        </div>

        <div class="cat">
          <strong>Imagens</strong>
          <p>![Texto alternativo da imagem](URL_da_imagem)</p>
        </div>

        <div class="cat">
          <strong>Código</strong>
          <p>`codigo`</p>
          <p>```bloco```</p>
        </div>

        <div class="cat">
          <strong>Linhas horizontais</strong>
          <p>---</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { marked } from "marked";
import "@/styles/editor.scss";

export default {
  data() {
    return {
      markdownText: "",
    };
  },
  computed: {
    compiledMarkdown() {
      return marked(this.markdownText, { sanitize: true });
    },
  },
};
</script>
