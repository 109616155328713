<template>
  <div class="login-footer-container">
    <strong>©2024 Idealize App | Por Grupo</strong>
    <img src="../../assets/img/Crew.png" alt="Crew" />
  </div>
</template>

<script>
import "@/styles/components/loginFooter.scss";

export default {
  name: "AppLoginFooter",
  data: function () {
    return {};
  },
};
</script>
