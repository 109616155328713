<template>
  <div class="general-login-container">
    <div class="login-container">
      <form @submit.prevent="login">
        <div class="form-container">
          <img src="../assets/img/AppLogo.png" alt="Idelize" />

          <div class="form-fields">
            <div class="fields">
              <input v-model="email" type="email" placeholder="E-mail" />
              <input v-model="password" type="password" placeholder="senha" />
            </div>

            <div class="sub-fields">
              <div class="check-field">
                <input
                  name="lembreme"
                  type="checkbox"
                  placeholder="Lembrar-me"
                />
                <label for="lembreme">Lembrar-me</label>
              </div>

              <a href="">Esqueci minha senha</a>
            </div>

            <button type="submit">Entrar</button>
            <router-link to="/cadastro">Criar nova conta</router-link>
          </div>
        </div>
      </form>
    </div>
    <AppLoginFooter />
  </div>
</template>

<script>
import "@/styles/login.scss";
import AppLoginFooter from "../components/scaffold/AppLoginFooter.vue";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import Swal from "sweetalert2";

export default {
  name: "AppLogin",
  components: { AppLoginFooter },
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    async login() {
      const auth = getAuth();
      signInWithEmailAndPassword(auth, this.email, this.password)
        .then((userCredential) => {
          const user = userCredential.user;

          if (user) {
            Swal.fire({
              title: "Login realizado com sucesso!",
              text: "Bem-vindo ao Idealize!",
              icon: "success",
              confirmButtonText: "Ok",
            });
            this.$router.push("/painel");
            this.$store.dispatch("setUser", user);
          }
        })
        .catch((error) => {
          console.error("ERRO", error);
          Swal.fire({
            title: "Falha ao realizar login!",
            text: "Por favor tente novamente",
            icon: "error",
            confirmButtonText: "Ok",
          });
        });
    },
  },
};
</script>
