<template>
  <div class="dash-container">
    <AppPageHeader pageTitle="Lixeira">
      <div class="action-buttons">
        <img src="../assets/img/icons/ic_plus.png" />
        <img src="../assets/img/icons/ic_notf.png" />
      </div>
    </AppPageHeader>

    <div class="info-cards">
      <div class="card" v-for="(item, index) in info" v-bind:key="index">
        <strong>{{ item.title }}</strong>
        <strong>{{ item.value }}</strong>
      </div>
    </div>
  </div>
</template>

<script>
import "@/styles/dashboard.scss";
import AppPageHeader from "../components/scaffold/AppPageHeader.vue";

export default {
  name: "AppTrash",
  components: {
    AppPageHeader,
  },
  data: function () {
    return {
      info: [
        {
          title: "N° de Ideias",
          value: 8,
        },
        {
          title: "N° de Categorias",
          value: 12,
        },
      ],
    };
  },
  mounted() {},
};
</script>
